<template>
  <CustomCursor/>
  <div class="container mx-auto text-gray-900 about">
    <div class="flex w-full pt-64 space-x-48 justify-space-between">
      <div class="z-30">
        <p class="text-4xl font-semibold md:text-5xl lg:text-7xl">Nice to meet you!</p>
        <p class="pt-8 text-2xl font-normal leading-7">
          My name is Silvia - <span class="italic">Silviaemme for friends</span> -
          and I’m a Product Designer (UX/UI).
          I work from a lifelong love for art, design and tech, enjoying figuring out how to make things work better and help people.
        </p>
        <p class="pt-8 text-2xl font-normal leading-7">
          I’m currently working as a remote UI designer for a company in the hotel industry based in Milan, where I help design a revenue management system.
        </p>
      </div>
      <img 
        src="/assets/img/silvia.png" 
        alt="silvia"
        class="w-80 h-80">
    </div>
    <div class="flex items-center w-full space-x-48 justify-space-between">
      <div>
        <h3 class="pt-32">
          I love bringing together <br> usability and aesthetic.
        </h3>
        <p class="pt-4 text-2xl font-normal leading-7">
          Initially starting out in web design and frontend development, I got a Bachelor’s Degree and worked for a web agency, where I gained experience designing products for several clients.
        </p>
        <p class="pt-8 text-2xl font-normal leading-7">
          To take my journey into Product Design to the next level, I decided to enroll an online UX/UI Bootcamp, learning the latest workflows and tools with other 15 designers around the world.
        </p>
      </div>
      <img class="w-64 h-full pt-20" src="/assets/img/ilove2.gif">
    </div>
    <div class="img-grid md:px-20">
      <div 
        v-for="image in 11" 
        :key="image" 
        :class="`div${image}`">
        <img :src="`/assets/img/About/${image}.jpg`">
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import Footer from '@/components/Footer.vue';
  import CustomCursor from '@/components/CustomCursor.vue';

  export default {
    data() {
      return {
        isImageDisplayed: false,
      }
    },
    components: {
      Footer,
      CustomCursor
    }
  }
</script>

<style lang="scss" scoped>

  .img-grid {
    columns: 3 200px;
    column-gap: 4rem;
    div {
      display: inline-block;
      width: 100%;
      height: 100%;
      text-align: center;
    } 
    @for $i from 1 through 11 { 
      div:nth-child(#{$i}) {
        $m: (random(250) + 100) + px;
        margin-top: $m;
      }
    }
  }
  
</style>